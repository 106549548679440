/**
* External Dependencies
*/

import 'jquery';
var $ = require('jquery');
import Swiper from 'swiper/bundle';

$(document).ready(function() {

  var Swipes = new Swiper('.swiper-container', {
    loop: false,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 16
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 2,
        spaceBetween: 24
      },
      // when window width is >= 480px
      1024: {
        slidesPerView: 3,
        spaceBetween: 24
      },
    },
    parallax: true,
    preloadImages: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '"></span>';
      },
    },
  });

  // VIDEO MUTED
  $('video').prop('muted', true);

  $('#mute').click( function () {
    if( $('video').prop('muted') ) {
      $('video').prop('muted', false);
      $('body').addClass('unmuted');
    } else {
      $('video').prop('muted', true);
      $('body').removeClass('unmuted');
    }
  });

  // MENU
  $('.hamburger').click(function() {
    $(this).toggleClass('active');
    $('body').toggleClass('hamburger-active');
    $('html').toggleClass('overflow-hidden');
  });

  $('.close-nav').click(function() {
    $('.hamburger').toggleClass('active');
    $('body').toggleClass('hamburger-active');
    $('html').toggleClass('overflow-hidden');
  });

  // Filter
  $('.open--filter').click(function() {
    $(this).toggleClass('active');
    $('body').toggleClass('filter-active');
    $('html').toggleClass('overflow-hidden');
  });

  $('.close--filter').click(function() {
    $(this).toggleClass('active');
    $('body').toggleClass('filter-active');
    $('html').toggleClass('overflow-hidden');
  });


  // Faq
  const items = document.querySelectorAll(".accordion-title");
  var i;

  function toggleAccordion() {
    const itemToggle = this.getAttribute('aria-expanded');

    for (i = 0; i < items.length; i++) {
      items[i].setAttribute('aria-expanded', 'false');
    }

    if (itemToggle == 'false') {
      this.setAttribute('aria-expanded', 'true');
    }
  }

  items.forEach(item => item.addEventListener('click', toggleAccordion));

  // Share
  $('.open--share').click(function() {
    $('body').toggleClass('share-active');
  });

  $('.close--share').click(function() {
    $('body').toggleClass('filter-active');
  });

  $('.open--search').click(function() {
    $('body').toggleClass('search-active');
    setTimeout(function() {
     $("#autoCompleteSearch").focus();
   }, 250);
  });

  $('.close--search').click(function() {
    $('body').toggleClass('search-active');
  });

  var max_images    = 4;
  var interval_time = 3000;

  var interval      = null;

  var active_images = [];
  var hidden_images  = [];


  setup();

  // interval for switching
  interval = setInterval(loop, interval_time);

  function setup() {
    // loop through all images and hide the others
    $('.partners--item').each(function(index, element) {
      if(index >= max_images) {
        $(this).hide();
      } else {
        $(this).show();
      }
    });
  }

  function loop() {

    hidden_images = $('.partners--item:hidden').each(function(index2, element1) {
        return element1;
    });

    active_images = $('.partners--item:visible').each(function(index1, element2) {
        return element2;
    });

    var active_random = Math.floor(Math.random() * active_images.length);
    var hidden_random = Math.floor(Math.random() * hidden_images.length);

    var rand_image_hidden = $(hidden_images[hidden_random]);
    var rand_image_active = $(active_images[active_random]);

    swap(rand_image_hidden, rand_image_active);

    rand_image_hidden.fadeIn();
    rand_image_active.fadeOut();

    rand_image_hidden.show();
    rand_image_active.hide();

  }

  $( window ).resize(function(e) {

    if(this.resizeTO) clearTimeout(this.resizeTO);

    this.resizeTO = setTimeout(function() {

      setup();

    }, 500);
  });

  window.addEventListener("blur", function(){
    clearInterval(interval);
    interval = null;
  });

  window.addEventListener("focus", function(){
    interval = setInterval(loop, interval_time);
  });

  function swap(a, b) {
    a = $(a); b = $(b);
    var tmp = $('<span>').hide();
    a.before(tmp);
    b.before(a);
    tmp.replaceWith(b);
  };

  // Knowledge base
  // $('.knowledge-checkbox').change(handleCatFilter);
  //
  // function handleCatFilter () {
  //
  //   var uristring = '?category=';
  //
  //   $('.knowledge-checkbox:checked').each(function(index) {
  //     var isLastElement = index == $('.knowledge-checkbox:checked').length -1;
  //
  //     uristring += $(this).val();
  //
  //     if(!isLastElement) {
  //       uristring += ',';
  //     }
  //
  //   });
  //
  //   window.location.href = uristring;
  // }

});
